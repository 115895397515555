
import { Component, Vue, Watch } from 'vue-property-decorator'
import VueObserveVisibility from 'vue-observe-visibility'
Vue.use(VueObserveVisibility)

import { UserRole } from '@/enums'
import { UsersState, UserGroup, UserWithEmail, NodeWithAlias,
  UserGroupInsert, UserGroupNodeInsert, UserGroupUserInsert,
  UserGroupNodeDelete, UserGroupUserDelete } from '@/types/state'

/* types */
type VueForm = Vue & { validate(): boolean, reset(): void, resetValidation(): void }

/* enums */
enum SnackbarStatus {
    FAIL,
    SUCCESS
}

/* helper functions */
const requiredField  = function(text?: string) { return (input: string): boolean | string => (input !== null && input !== "") || (text ?? "Verplicht") }
const onlyNumbers    = function(text?: string) { return (input: string): boolean | string => /^\d+$/.test(input) || (text ?? "Vul alleen cijfers in") }
const requiredSelect = function(text?: string) { return (input: any): boolean | string    => (input && input != { }) || (text ?? "Verplicht") }
const maxCharacters  = function(max: number, text?: string) {
    return (input: string): boolean | string => (input == "" || (input && input.length <= max)) || (text ?? `Maximaal ${max} karakter${max != 1 ? "s" : ""}`)
}


@Component({
    components: {
    }
})
export default class EmraPage extends Vue {

  SNACKBAR_TIMEOUT_LENGTH = 3000
  SNACKBAR_SUCCESS_COLOR = "#9fc735"
  SNACKBAR_FAIL_COLOR    = "#d62328"

  DATA_TABLE_HEADERS = [ { value: "obj" } ]
  NO_NODES_CONNECTED_TEXT = "Geen EMRA-boxen gekoppeld"
  NO_USERS_CONNECTED_TEXT = "Geen gebruikers gekoppeld"

  ADD_USER_GROUP_RULES = [ requiredField(), maxCharacters(32) ]
  ADD_NODE_RULES       = [ requiredSelect() ]
  ADD_USER_RULES       = [ requiredSelect() ]

  // Search functionality
  searchValue = ''
  nameFilter = 1
  filterFullAccess = 0
  filterByUserEmail = 0

  resetFilters() {
    this.filterFullAccess = 0
    this.filterByUserEmail = 0
  }

  // Snackbar
  snackbar      = false
  snackbarText  = ""
  snackbarColor = ""

  openedLists: any = { }
  nodesToDelete: string[] = []
  usersToDelete: string[] = []

  showAddUserGroupForm = false
  showDeleteUserGroupForm = false
  showAddNodeForm = false
  showAddUserForm = false

  addFormValid = false
  updatingList = false

  newUserGroupName = ""
  fullAccessSelected = false
  selectedObject = ""
  
  get nameFilterIcon(): string {
    switch(this.nameFilter) {
      case 0:   return 'mdi-sort-alphabetical-variant'
      case 1:   return 'mdi-sort-alphabetical-ascending'
      default:  return 'mdi-sort-alphabetical-descending'
    }
  }

  get userRole(): string { return this.$store.getters['identity/role'] }

  get isModOrAdmin(): boolean {
    return this.userRole == 'Moderator' || this.userRole == 'Admin'
  }

  get loading(): boolean { return this.$store.getters['users/Loading'] }
  get userGroups(): UserGroup[] { return this.$store.getters['users/UserGroups'] }
  get allNodes(): NodeWithAlias[] { return this.$store.getters['users/AllNodes'] }
  get allUsers(): UserWithEmail[] { return this.$store.getters['users/AllUsers'] }

  filterGroupsByName(userGroups: Array<UserGroup>): Array<UserGroup> {
    return (this.nameFilter == 0) ? 
      userGroups :
      (this.nameFilter == 1) ? 
        userGroups.sort((a: UserGroup, b: UserGroup) => a.name > b.name ? 1 : -1) :
        userGroups.sort((a: UserGroup, b: UserGroup) => a.name > b.name ? -1 : 1)
  }

  get filteredUserGroups(): UserGroup[] {
    const filteredGroups = this.userGroups.filter(ug => (this.filterFullAccess == 0 ? true : (this.filterFullAccess == 1 ? ug.fullAccess : !ug.fullAccess))
      && (this.filterByUserEmail == 0 ? ug.name.toLowerCase().includes(this.searchValue.toLowerCase())
                                      : (ug.users.find(u => u.email.toLowerCase().includes(this.searchValue.toLowerCase()))))
    )

    return this.filterGroupsByName(filteredGroups)
  }

  get openedListId(): string | undefined {
    const keys = Object.keys(this.openedLists)
    return keys.find(k => this.openedLists[k] == true)
  }

  get userGroupThatIsOpened(): UserGroup|undefined {
    const openedId = this.openedListId
    return openedId ? this.userGroups.find(ug => ug.id == openedId) : undefined
  }

  closeOpenedList(): void {
    const openedId = this.openedListId
    if (openedId)
      this.openedLists[openedId] = false
  }

  get nodesNotInGroup(): NodeWithAlias[] {
    return this.allNodes?.filter(n => !this.userGroupThatIsOpened?.nodes?.find(oln => oln.id == n.id))
  }

  get usersNotInGroup(): UserWithEmail[] {
    return this.allUsers?.filter(u => !this.userGroupThatIsOpened?.users?.find(olu => olu.id == u.id))
  }

  get itemsToDelete(): number {
    return this.usersToDelete.length + this.nodesToDelete.length
  }

  get currentForm(): VueForm {
    return this.$refs[this.showAddNodeForm ? "add-node-form"
                    : (this.showAddUserForm ? "add-user-form"
                    : (this.showAddUserGroupForm ? "add-user-group-form"
                    : (this.showDeleteUserGroupForm ? "delete-user-group-form"
                    : "")))] as VueForm
  }

  get isPopupOpened(): boolean {
    return this.showAddNodeForm || this.showAddUserForm || this.showAddUserGroupForm || this.showDeleteUserGroupForm
  }

  userGroupDataTableItems(userGroup: UserGroup, isNodeList: boolean): any[] {
    if (isNodeList) {
      return userGroup.nodes.map((n: NodeWithAlias) => ({
        obj: {
          id: n.id,
          text: n.alias
        }
      }))
    } else {
      return userGroup.users.map((u: UserWithEmail) => ({
        obj: {
          id: u.id,
          text: u.email
        }
      }))
    }
  }

  toggleDeleteItem(isNodeList: boolean, value: any): void {
    const list = isNodeList ? this.nodesToDelete : this.usersToDelete
    if (!list.includes(value))
      list.push(value)
    else
      list.splice(list.indexOf(value), 1)
  }

  @Watch("openedLists", { deep: true })
  resetItemsToDelete(): void {
    this.nodesToDelete = []
    this.usersToDelete = []
  }

  @Watch("searchValue")
  closeListIfNotInFilteredGroups(): void {
    if (this.userGroupThatIsOpened && !this.filteredUserGroups.find(ug => ug.id == this.userGroupThatIsOpened?.id)) {
      this.closeOpenedList()
    }
  }

  @Watch("showAddUserGroupForm")
  resetAddUserGroupForm(): void {
    this.newUserGroupName = ""
    this.fullAccessSelected = false
  }

  async deleteSelectedItems(): Promise<void> {
    if (!this.userGroupThatIsOpened)
      return
    
    if (this.nodesToDelete.length > 0) {
      await this.$store.dispatch('users/removeNodesFromUserGroup', { userGroupId: this.userGroupThatIsOpened.id, nodeIds: this.nodesToDelete })
        .then((result: any) => {
          if (!result.err)
            this.nodesToDelete = []
        })
    }
    
    if (this.usersToDelete.length > 0) {
      await this.$store.dispatch('users/removeUsersFromUserGroup', { userGroupId: this.userGroupThatIsOpened.id, userIds: this.usersToDelete })
        .then((result: any) => {
          if (!result.err)
            this.usersToDelete = []
        })
    }
  }

  vClickOutsideIncluded(): HTMLElement[] {
      const classes = ["includeClickOutside"]
      const result: HTMLElement[] = []

      classes.forEach(name => {
          const elements = document.getElementsByClassName(name);
          (Array.from(elements) as HTMLElement[]).forEach(el => {
              result.push(el)
          })
      })

      return result
  }

  hidePopupForms(): void {
    this.showAddUserGroupForm = false
    this.showDeleteUserGroupForm = false
    this.showAddNodeForm = false
    this.showAddUserForm = false
  }

  get inputAlreadyExists(): boolean {
      if (this.showAddUserGroupForm) {
        return this.userGroups.findIndex(ug => ug.name.toLowerCase() == this.newUserGroupName.toLowerCase()) >= 0
      }
      return false
  }

  async validateAndSaveData(): Promise<boolean> {
    if (!this.currentForm.validate() || this.inputAlreadyExists) return false

    if ((this.showAddNodeForm || this.showAddUserForm || this.showDeleteUserGroupForm) && !this.userGroupThatIsOpened) {
      this.snack("Kon wijziging niet doorvoeren: geen groep geopend", SnackbarStatus.FAIL)
      return false
    }

    this.updatingList = true
    const openedListId = this.userGroupThatIsOpened?.id

    let data!: UserGroupNodeInsert|UserGroupUserInsert|UserGroupInsert|string,
        action!: string,
        snackText = "Data opgeslagen",
        success = true

    if (this.showAddNodeForm) {
      data = { userGroupId: this.userGroupThatIsOpened!.id, nodeId: this.selectedObject }
      action = "users/addNodeToUserGroup"
    } else if (this.showAddUserForm) {
      data = { userGroupId: this.userGroupThatIsOpened!.id, userId: this.selectedObject }
      action = "users/addUserToUserGroup"
    } else if (this.showAddUserGroupForm) {
      data = { userGroupId: "", name: this.newUserGroupName, fullAccess: this.fullAccessSelected }
      action = "users/addUserGroup"
    } else if (this.showDeleteUserGroupForm) {
      data = this.userGroupThatIsOpened!.id
      action = "users/deleteUserGroup"
    }
    
    try {
      await this.$store.dispatch(action, data)
        .then((result: any) => {
          if (!result.err) {

            if (this.showDeleteUserGroupForm) {
              // Make sure the selected list is closed when a group is deleted
              if (openedListId) {
                this.openedLists[openedListId] = false
                delete this.openedLists[openedListId]
              }

              snackText = "Groep verwijderd"
            }

          } else {
            snackText = "Fout: data niet opgeslagen"
            success = false
          }
        })
    } catch (_) {
      snackText = "Fout: data niet opgeslagen"
      success = false
    }

    this.updatingList = false
    this.hidePopupForms()

    this.snack(snackText, success ? SnackbarStatus.SUCCESS : SnackbarStatus.FAIL)

    return success
  }

  snack(text: string, status: SnackbarStatus): void {
    this.snackbarColor = status === SnackbarStatus.FAIL ? this.SNACKBAR_FAIL_COLOR : this.SNACKBAR_SUCCESS_COLOR
    this.snackbarText  = text
    this.snackbar      = true
  }



  async created(): Promise<void> {
    window.addEventListener("keydown", (k) => {
      if (k.key == "Escape") {
        if (this.isPopupOpened) {
          this.hidePopupForms()
        } else {
          this.closeOpenedList()
        }
      }
    })

    if (this.userRole == UserRole.Moderator || this.userRole == UserRole.Admin)
      this.$store.dispatch('users/init')
  }

}
